/**
 * Created by author
 */
// 保存搜索条件参数变量
export const SAVE_SEARCH_PARAMS = 'SAVE_SEARCH_PARAMS'
export const SAVE_SEARCH_CATEGORY_PARAMS = 'SAVE_SEARCH_CATEGORY_PARAMS'
export const PREFIX = 'PREFIX'
export const TRAVELLER_PREFIX = 'TRAVELLER_PREFIX'
export const COUNTRY = 'COUNTRY'
export const COUNTRY_NAME = 'COUNTRY_NAME'
export const TRAVELLERS_INFO = 'TRAVELLERS_INFO'
export const SELECTED_TRAVELLERS_INFO = 'SELECTED_TRAVELLERS_INFO'
export const SAVE_TRAVELLERS_NUM = 'SAVE_TRAVELLERS_NUM'
export const SAVE_BOOKING_EMAIL = 'SAVE_BOOKING_EMAIL'
export const SAVE_START_END_DATE = 'SAVE_START_END_DATE'
export const SAVE_AREA_ID = 'SAVE_AREA_ID'
export const SAVE_AREA_NAME = 'SAVE_AREA_NAME'

