/**
 * Created by author
 */
import React from 'react';
import { hot } from 'react-hot-loader/root';
import Routes from '../router/index';

const App = () => {
    return (
        <div className="pc-container" style={{'maxWidth': '750px'}}>
            <Routes />
        </div>
    );
};

App.defaultProps = {
    props: {},
};

/* https://github.com/gaearon/react-hot-loader#getting-started */
export default hot(App);