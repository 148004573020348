
/**
 * 台湾/繁体 翻译
 *//**
  * Created by author
  */
const ko_KR = {
    loading: '加載中...',
    loading_more: '加載更多...',
    loading_end: '我是有底線的',
    select_title: '選擇',
    close_myself_page_tips: '個人中心功能正在向您奔跑而來',
    close_page_hold_on: '請稍等下喲',
    all_page_title_list : {
      user_info_title: '個人信息',
      collect_title: '我的收藏',
      travellers_info_title: '常用旅客',
      contact_landlord_title: '聯繫房東',
      setting_title: '設置',
      setting_language_title: '設置語言',
      setting_currency_title: '設置幣種',
      setting_password_title: '修改密碼',
      add_traveller_title: '新增旅客信息',
      edit_traveller_title: '編輯旅客信息',
      search_title: '篩選',
      service_policy_title: '服務條款',
      select_area_code_title: '選擇區號',
      select_country_title: '選擇國家或地區',
      select_area_name_title: '選擇地址',
      pay_title: '支付',
      order_title: '訂單',
      order_detail_title: '訂單詳情',
      refund_request_title: '申請退款',
      lodger_info_title: '房客信息',
      add_evaluate_title: '評價',
      refund_policy_title: '退訂政策',
      select_date_title: '日期選擇',
      booking_detail_title: '預訂詳情',
      booking_detail_price_title1: '價格明細',
      booking_detail_price_title: '預訂',
      add_lodger_title: '添加房客',
      offline_house_title: '房源已下線',
      four_zero_four: '404',
      five_zero_zero: '500',
      detail_maps_title: '地圖',
      person_center_page_title: '個人中心',
      air_booking_detail_price_title: '接送機',
      air_booking_detail_price_title_detail: '接送機詳情',
    },
    my_page: {
      new_account_nickname: '新用戶',
      login__account_placeholder: '登錄',
      my_desc_title: '描述',
    },
    tabbar: {
      home_page_title: '首頁',
      category_page_title: '分類',
      order_page_title: '訂單',
      my_page_title: '我的',
    },
    user_info: {
      save: '保存',
      save_success: '保存成功',
      edit_success: '編輯成功',
      nick_name: '暱稱',
      nick_name_placeholder: '請輸入暱稱',
      phone_number: '手機號碼',
      email: '郵箱',
      email_placeholder: '請輸入郵箱',
    },
    landlord: {
      landlord_intro: '描述',
      landlord_contact_info: '聯繫方式',
    },
    setting: {
      setting_lougot: '退出登錄',
    },
    travellers: {
      add_traveller_info: '增加常用旅客',
      save_success: '保存成功',
      edit_success: '編輯成功',
      china_name: '姓名',
      country_area: '國家/地區',
      cert_type: '證件類型',
      submit: '確定',
      cancel: '取消',
      china_name_placeholder_tips: '請輸入護照姓名(英文)',
      name_placeholder_tips: '請填寫姓名',
      sex_placeholder_tips: '請選擇性別',
      country_placeholder_tips: '請選擇國家',
      cert_type_placeholder_tips: '請選擇證件類型',
      prefix_placeholder_tips: '請選擇手機區號',
      cert_number: '證件號碼',
      cert_number_placeholder: '請輸入證件號碼',
      phone_num: '手機號',
      phone_num_placeholder: '請輸入手機號',
      delete_contact: '刪除該聯繫人',
      save: '保存',
      sex: '性別',
      sex_male: '男',
      sex_female: '女',
      cert_id_card: '身份證',
      cert_id_card_last_num: '尾號',
      cert_passport: '護照',
      delete_success_tips: '刪除成功',
    },
    empty_page_tips: {
      travellers_empty_tips: '您還沒有旅客信息，快去添加吧',
      collect_empty_tips: '您還沒有收藏過任何活動',
      order_empty_tips: '您還沒有此類型訂單',
      offline_house_empty_tips: '抱歉，當前房源已經下線',
      no_store_empty_tips: '抱歉，當前店鋪不存在，請與房東確認後再打開',
    },
    search: {
      search_address_placeholder: '請選擇地址',
      search_address_title: '地址',
      search_date_title: '日期',
      search_room_num_title: '臥室',
      search_room_num_placeholder: '請輸入臥室數',
      search_people_title: '人數',
      search_price_title: '價格區間',
      search_min_price: '最低價',
      search_max_price: '最高價',
      search_keyword: '關鍵詞',
      search_keyword_placeholder: '請輸入標題關鍵詞搜索',
      search_submit_selected: '確認選擇',
      clear_search_condition: '清除搜索條件',
      clear_success: '清除成功',
    },
    login: {
      register: '註冊',
      register_send_email: '發送郵件，進行驗證註冊',
      register_send_email_verify: '發送郵件，進行驗證驗證',
      register_send_email_forget: '發送郵件，驗證找回密码',
      email_register: '郵箱註冊',
      email_login: '郵箱登錄',
      email_account: '郵箱帳號',
      email_find: '找回密碼',
      email_forget: '忘記密碼',
      email_placeholder: '請輸入郵箱帳號',
      email_placeholder_tips: '請輸入郵箱接受預訂信息',
      email_verify_placeholder: '郵件已經發送到您的郵箱，請前往郵箱進行驗證',
      verify_message: '驗證郵件',
      verify_email_by_password: '設置密碼，驗證郵箱帳號',
      verify_email_by_password_success: '驗證成功，快去登錄吧',
      login: '登錄',
      please_phone_num: '請填寫手機號碼',
      send_sms_code: '發送驗證碼',
      sms_code_success: '驗證碼已發送到您的手機，請查收',
      sms_email_code_success: '验证码已发送到您的郵箱，請查收',
      please_password: '請設置密碼',
      please_password_placeholder: '請填寫密碼',
      please_sms_code: '請填寫驗證碼',
      sms_code_login_title: '驗證碼登錄',
      password_login_title: '密碼登錄',
      phone_num: '手機號碼',
      password: '密碼',
      sms_code: '驗證碼',
      login_agree: '登錄即代表同意',
      policy_title: '《服務條款》',
      no_account_tips: '還沒有賬號？ ',
      has_account_tips: '已經有賬號',
      login_success_tips: '登錄成功',
      reset_password_success_tips: '密碼重置成功',
      logout_success_tips: '登出成功',
      user_none_error_tips: '信箱不存在或密碼錯誤',
      login_to_to_home: '暫不登錄',
      nav_to_home_page: '前往首頁',
    },
    category: {
      look_all: '查看全部'
    },
    pay: {
      select_pay_type: '請選擇支付方式',
      alipay_type: '支付寶',
      wechat_type: '微信',
      paypal_type: 'PayPal',
      stripe_type: 'Stripe',
      submit_pay_btn: '確認支付',
      reserve_success_btn: '支付成功',
      reserve_failed_btn: '支付失敗',
      wait_you_coming: '敬候您的入住',
      look_order_detail: '查看訂單',
      pay_paypal_tips: '您選擇的當前支付方式，將會收取的費用是：',
      paying_tips: '支付進行中，請稍等...',
    },
    order: {
      all_order_title: '全部',
      going_order_title: '進行中',
      finish_order_title: '已完成',
      order_num: '訂單號',
      order_status: '待付款',
      total_price: '合計',
      house_total_price: '房源費用合計',
      pick_up_drop_off_total_price: '接送機費用合計',
      order_reserve_date: '日期',
      people_num_date: '房客',
      deposit_num: '押金',
      deposit_will_return_back: '(押金將在退房成功後自動退還)',
      room_price: '房費',
      look_price_detail: '查看明細',
      price_around: '平均每晚價格四捨五入計算',
      clean_price: '清潔費',
      airbnb_fee_price: 'Airbnb服務費',
      one_clean_price: '房東收取的一次性清潔費，用於清潔房源',
      order_info: '訂單信息',
      order_number: '訂單號',
      order_time: '下單時間',
      pay_time: '付款時間',
      pay_type: '付款方式',
      pay: '付款',
      cancel_order_refund: '協商退款',
      cancel_order_refund_model_title: '請先與房東協商退款事項,你確定取消該訂單进行退款嗎？',
      cancel_order_refund_success: '已經申請，請和房東再次確認退款',
      cancel_order: '取消訂單',
      cancel_order_model_title: '請先與房東協商退款事項,你確定取消該訂單进行退款嗎？',
      pay_money: '支付',
      cancel_order_success: '訂單取消成功',
      people_num: '房客數',
      order_state_0: '待確認',
      order_state_1: '待支付',
      order_state_2: '待入住',
      order_state_3: '已超時',
      order_state_4: '已完成',
      order_state_5: '已取消',
      order_state_6: '房東拒絕',
      order_state_7: '支付失敗',
      order_state_8: '退款申請中',
      order_state_9: '退款完成',
      order_state_10: '退款拒絕',
      nav_to_order_detail_page: '訂單創建成功，正在跳轉到訂單詳情',
      submit_confirm: '確認提交',
    },
    house: {
      house_max_people: '當前房源最大入住人數是',
      select_people_title: '選擇人數',
      collect_success: '收藏成功',
      cancel_collect: '已經取消收藏',
      house_room: '室',
      house_bath_num: '衛',
      house_bed_num: '床',
      house_people_num: '最多入住%s人',
      house_check_in_check_out: '入住時間/退房時間',
      house_min_days: '最短入住時間',
      days: '天',
      cancel_policy: '退訂政策',
      calendar_btn: '日曆',
      ok_btn: '好的',
      cancel_policy_tips: '1、預訂时间距離入住時間還有14天，可在預訂后48小時內免費取消。',
      cancel_policy_tips2: '2、此後，距離入住時間還有15天退50%房費，15天之內不退還。',
      compare_price_other: '比價',
      house_chat_info: '聊天',
      copy_chat_title: '複製聯繫方式',
      copy_chat_tips: '直接點擊對應的聯繫方式進行複制',
      copy_tips: '複製',
      copy_line_tips: '複製LINE',
      copy_wechat_tips: '複製Wechat',
      copy_chat_wechat: 'Wechat複製成功，已經復製到剪切板，快去微信裡添加好友吧',
      copy_chat_line: 'LINE複製成功，已經復製到剪切板，快去添加好友吧',
      copy_chat_close_btn: '關閉',
      compare_price_airbnb: '比Airbnb房源',
      compare_price_agoda: '比Agoda房源',
      compare_price_booking: '比Booking房源',
      compare_price_percent: '優惠5% - 30%',
      add_lodger_tips: '您所填信息將不會被洩漏。僅因相關部門規定，入住中國民宿必須提供以下信息，您的信息將由我們儲存並處理，完成本次預訂，您同意我們可以向中國政府披露您的信息而不向您提供進一步通知。',
      add_new_lodger: '新增房客信息',
      plase_select_num_tips: '請選擇和房客人數相同的旅客信息',
    },
    booking: {
      look_pay_method: '查看支付方式',
      submit_info_to_order: '提交訂單',
      date: '日期',
      night: '晚',
      date_placeholder: '請選擇日期',
      travellers_num: '房客(人數)',
      travellers_num_placeholder: '請選擇入住的房客人數',
      num_per: '位',
      email: '郵箱',
      email_placeholder: '將向郵箱發送預定信息',
      email_placeholder_tips: '請填寫郵箱接受預訂資訊',
      price_title: '費用摘要',
      room_price_total: '房費總額',
      room_fee: '房費',
      price_round: '平均每晚價格四捨五入計算',
      extra_people_fee: '額外人員費用',
      clean_price: '清潔費',
      airbnb_fee_price: 'Airbnb服務費',
      clean_price_tips: '房東收取的一次性清潔費，用於清潔房源',
      deposit: '入住押金',
      deposit_not: '無押金',
      deposit_tips: '退房後無物品丟失損壞，24小時內全額返回押金',
      total_price: '合計',
      house_total_price: '房源合計',
      pick_up_total_price: '接機合計',
      drop_off_total_price: '送機合計',
      people: '人',
      extra_people_max_num: '超過%s人收取',
      traveller_num_placeholder: '請選擇入住人資訊',
      traveller_people_num: '請選擇%s個入住人資訊',
      traveller_info_title: '入住人資訊',
      traveller_info_title_placeholder: '請填寫入住人資訊',
      booking_house_tips_email: '填寫的郵箱將接受預訂資訊，請再次確認是否準確。',
      'seven_cards': '七座車',
      'ten_cards': '十座車',
      'pick_up_name': ' 的接機',
      'drop_off_name': '的送機',
      'pick_up_at': '預訂時間',
      'fight_no': '航班',
      'pick_up_people_num': '乘客人數',
    },
    home: {
      date_num_price: '日期/人數/價格',
      house_title: '民宿',
      people_num_format: '%s人',
    },
    calendar: {
      date_not_rent: '當前日期不可租',
      not_rent: '不可租',
      check_in: '入住',
      check_out: '退房',
      can_check_out: '可退房',
      check_in_check_out_not_one_day: '退房日期不能跟入住日期在同一天',
      check_in_less_than_check_out: '退房日期不能小於入住日期',
      check_in_on_not_rent_tips: '選擇的日期包含不可租日期,請重新選擇',
      placeholder_check_out_time: '請選擇結束時間',
      check_date_min_days_tips: '選擇入住的天數不能少於%s天',
      mon: '一',
      tue: '二',
      wed: '三',
      thur: '四',
      fri: '五',
      sat: '六',
      sun: '日',
      clear: '清除',
      confirm: '確認',
    },
    error: {
      error_no_page: '暫無此頁面',
      nav_to_home_page: '前往首頁',
    },
    ad: {
      page_title: '接送機服務',
      page_title_pick_up: '出發地：請輸入位址',
      page_title_spot: '目的地：請選擇機場',
      page_title_pick_up2: '出發地：請選擇機場',
      page_title_spot2: '目的地：請輸入位址',
      page_title_date: '請選擇日期',
      page_title_time: '請選擇時間',
      page_title_time_title: '時間選擇',
      page_title_people_num: '乘客人數',
      page_title_search_btn: '搜索',
      page_title_safety_seat_num : '嬰兒座椅（適用0-6歲）',
      page_title_fight_no_placeholder: '請輸入您的航班',
      page_booking_detail_placeholder: '請填寫航班號，方便司機進行接送',
      page_input_airport_placeholder: '請輸入機場',
      page_input_spot_placeholder: '請選擇位址',
      page_input_num_placeholder: '請選擇乘客人數',
    }
  };


export default ko_KR;
