/**
 * Created by author
 * 项目中的默认常量值、键值对、对象、数组
 * 1、默认语言类型
 * 2、默认语言类型数组
 * 3、默认语言类型 localstorage 变量值
 * 4、默认币种类型
 * 5、默认币种类型数组
 * 6、默认币种类型 localstorage 变量值
 * 7、默认存储的登录态 token localstorage 变量值
 */

/**
 * 默认的语言类型
 * @type {string}
 */
export const LANG_TYPE_DEFAULT = 'zh-CN';
/**
 * 目前支持的语言类型
 * @type {*[]}
 * classname 图标名称
 */
export const LANG_SUPPORT = [
    // {
    //     name: 'English',
    //     value: 'en-US',
    //     type: 'EN-US',
    // },
    {
        name: '简体中文',
        value: 'zh-CN',
        type: 'ZH-CMN-HANS',
    },
    {
        name: '繁體中文',
        value: 'zh-TW',
        type: 'ZH-CMN-HANT',
    },
    // {
    //     name: '日本の',
    //     value: 'ja-JP',
    //     type: 'JA-JP',
    // },
    // {
    //     name: '한국어',
    //     value: 'ko-KR',
    //     type: 'KO-KR',
    // },
];
/**
 * 默认语言存在localStorage的key或者其他键值对
 * */
export const LANG_STORAGE_DEFAULT = {
    lang_type: 'tenant_lang_type', // 语言常量
};

/**
 * 默认的货币币种类型
 * @type {string}
 */
export const CURRENCY_TYPE_DEFAULT = 'JPY';
/**
 * 目前支持的语言类型
 * @type {*[]}
 * classname 图标名称
 */
export const CURRENCY_SUPPORT = [
    {
        name: '人民币',
        value: 'CNY',
    }, {
        name: '港币',
        value: 'HKD',
    }, {
        name: '美元',
        value: 'USD',
    }, {
        name: '日元',
        value: 'JPY',
    }, {
        name: '新台币',
        value: 'TWD',
    },
    // {
    //     name: '新加坡元',
    //     value: 'SGD',
    // },
    // {
    //     name: '林吉特',
    //     value: 'MYR',
    // },
];
/**
 * 默认语言存在localStorage的key或者其他键值对
 * */
export const CURRENCY_STORAGE_DEFAULT = {
    currency_type: 'tenant_currency_type', // 语言常量
};

/**
 * 默认的存储的登录态 token
 * @type {string}
 */
export const HOME_TOKEN_KEY_DEFAULT = 'tenant_home_token';
/**
 * 默认的存储的店铺信息 shopalias
 * @type {string}
 */
export const HOME_SHOP_ALIAS_KEY_DEFAULT = 'tenant_home_shop_alias';
/**
 * 默认的存储的搜索参数
 * @type {string}
 */
export const SEARCH_PARAMS_DEFAULT = 'tenant_search_params';
/**
 * 存储到cookie里面的值 语言
 * @type {string}
 */
export const HOME_TENANT_LANGUAGE_DEFAULT = 'TenantLanguage';
/**
 * 存储到cookie里面的值 币种
 * @type {string}
 */
export const HOME_TENANT_CURRENCY_DEFAULT = 'TenantCurrency';
/**
 * 存储到cookie里面的值 token
 * @type {string}
 */
export const HOME_TENANT_TOKEN_DEFAULT = 'TenantAuthorization';
