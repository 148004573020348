/**
 * Created by author
 */
(function (doc, win) {
    // 自适应
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function () {
            var clientWidth = docEl.clientWidth
            if (!clientWidth) return
            if (clientWidth > 640) {
                docEl.style.fontSize = '25px'
                // 适配兼容pc页面显示h5页面
                docEl.style.maxWidth = '375px'
                docEl.style.marginLeft = 'calc(calc(100% - 375px)/2)'
            } else {
                docEl.style.fontSize = 25 * (clientWidth / 375) + 'px' // 放下16个字
                docEl.style.maxWidth = '100%'
                docEl.style.marginLeft = 0
            }

        }
    if (!doc.addEventListener) return
    win.addEventListener(resizeEvt, recalc, false)
    doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)