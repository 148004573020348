/**
 * Created by author
 */
/**
 * 中文翻译
 */
const zh_CN = {
    loading: '加载中...',
    loading_more: '加载更多...',
    loading_end: '我是有底线的',
    select_title: '选择',
    close_myself_page_tips: '个人中心功能正在向您奔跑而来',
    close_page_hold_on: '请稍等下哟',
    all_page_title_list : {
        user_info_title: '个人信息',
        collect_title: '我的收藏',
        travellers_info_title: '常用旅客',
        contact_landlord_title: '联系房东',
        setting_title: '设置',
        setting_language_title: '设置语言',
        setting_currency_title: '设置币种',
        setting_password_title: '修改密码',
        add_traveller_title: '新增旅客信息',
        edit_traveller_title: '编辑旅客信息',
        search_title: '筛选',
        service_policy_title: '服务条款',
        select_area_code_title: '选择区号',
        select_country_title: '选择国家或地区',
        select_area_name_title: '选择地址',
        pay_title: '支付',
        order_title: '订单',
        order_detail_title: '订单详情',
        refund_request_title: '申请退款',
        lodger_info_title: '房客信息',
        add_evaluate_title: '评价',
        refund_policy_title: '退订政策',
        select_date_title: '日期选择',
        booking_detail_title: '预订详情',
        booking_detail_price_title1: '价格明细',
        booking_detail_price_title: '预订',
        add_lodger_title: '添加房客信息',
        offline_house_title: '房源已下线',
        four_zero_four: '404',
        five_zero_zero: '500',
        detail_maps_title: '地图',
        person_center_page_title: '个人中心',
        air_booking_detail_price_title: '接送机',
        air_booking_detail_price_title_detail: '接送机详情',
    },
    my_page: {
        new_account_nickname: '新用户',
        login__account_placeholder: '登录',
        my_desc_title: '描述',
    },
    tabbar: {
        home_page_title: '首页',
        category_page_title: '分类',
        order_page_title: '订单',
        my_page_title: '我的',
    },
    user_info: {
        save: '保存',
        save_success: '保存成功',
        edit_success: '编辑成功',
        nick_name: '昵称',
        nick_name_placeholder: '请输入昵称',
        phone_number: '手机号码',
        email: '邮箱',
        email_placeholder: '请输入邮箱',
    },
    landlord: {
        landlord_intro: '描述',
        landlord_contact_info: '联系方式',
    },
    setting: {
        setting_lougot: '退出登录',
    },
    travellers: {
        add_traveller_info: '增加常用旅客',
        save_success: '保存成功',
        edit_success: '编辑成功',
        china_name: '姓名',
        country_area: '国家/地区',
        cert_type: '证件类型',
        submit: '确定',
        cancel: '取消',
        china_name_placeholder_tips: '请输入护照姓名(英文)',
        name_placeholder_tips: '请填写姓名',
        sex_placeholder_tips: '请选择性别',
        country_placeholder_tips: '请选择国家',
        cert_type_placeholder_tips: '请选择证件类型',
        prefix_placeholder_tips: '请选择手机区号',
        cert_number: '证件号码',
        cert_number_placeholder: '请输入证件号码',
        phone_num: '手机号',
        phone_num_placeholder: '请输入手机号',
        delete_contact: '删除该联系人',
        save: '保存',
        sex: '性别',
        sex_male: '男',
        sex_female: '女',
        cert_id_card: '身份证',
        cert_id_card_last_num: '尾号',
        cert_passport: '护照',
        delete_success_tips: '删除成功',
    },
    empty_page_tips: {
        travellers_empty_tips: '您还没有旅客信息，快去添加吧',
        collect_empty_tips: '您还没有收藏过任何活动',
        order_empty_tips: '您还没有此类型订单',
        offline_house_empty_tips: '抱歉，当前房源已经下线',
        no_store_empty_tips: '抱歉，当前店铺不存在，请与房东确认后再打开',
    },
    search: {
        search_address_placeholder: '请选择地址',
        search_address_title: '地址',
        search_date_title: '日期',
        search_room_num_title: '卧室',
        search_room_num_placeholder: '请输入卧室数',
        search_people_title: '人数',
        search_price_title: '价格区间',
        search_min_price: '最低价',
        search_max_price: '最高价',
        search_keyword: '关键词',
        search_keyword_placeholder: '请输入标题关键词搜索',
        search_submit_selected: '确认选择',
        clear_search_condition: '清除搜索条件',
        clear_success: '清除成功',
    },
    login: {
        register: '注册',
        register_send_email: '发送邮件，进行验证注册',
        register_send_email_verify: '发送邮件，进行验证验证',
        register_send_email_forget: '发送邮件，验证找回密码',
        email_register: '邮箱注册',
        email_login: '邮箱登录',
        email_account: '邮箱账号',
        email_find: '找回密码',
        email_forget: '忘记密码',
        email_placeholder: '请输入邮箱账号',
        email_verify_placeholder: '邮件已经发送到您的邮箱，请前往邮箱进行验证',
        verify_message: '验证邮箱',
        verify_email_by_password: '设置密码，验证邮箱账号',
        verify_email_by_password_success: '验证成功，快去登录吧',
        login: '登录',
        please_phone_num: '请填写手机号码',
        send_sms_code: '发送验证码',
        sms_code_success: '验证码已发送到您的手机，请查收',
        sms_email_code_success: '验证码已发送到您的邮箱，请查收',
        please_password: '请设置密码',
        please_password_placeholder: '请填写密码',
        please_sms_code: '请填写验证码',
        sms_code_login_title: '验证码登录',
        password_login_title: '密码登录',
        phone_num: '手机号码',
        password: '密码',
        sms_code: '验证码',
        login_agree: '登录即代表同意',
        policy_title: '《服务条款》',
        no_account_tips: '还没有账号？',
        has_account_tips: '已经有账号',
        login_success_tips: '登录成功',
        reset_password_success_tips: '密码重置成功',
        logout_success_tips: '登出成功',
        user_none_error_tips: '邮箱不存在或密码错误',
        login_to_to_home: '暂不登录',
        nav_to_home_page: '前往首页',
    },
    category: {
        look_all: '查看全部'
    },
    pay: {
        select_pay_type: '请选择支付方式',
        alipay_type: '支付宝',
        wechat_type: '微信',
        paypal_type: 'PayPal',
        stripe_type: 'Stripe',
        submit_pay_btn: '确认支付',
        reserve_success_btn: '支付成功',
        reserve_failed_btn: '支付失败',
        wait_you_coming: '敬候您的入住',
        look_order_detail: '查看订单',
        pay_paypal_tips: '您选择的当前支付方式，将会收取的费用是：',
        paying_tips: '支付进行中，请稍等...',
    },
    order: {
        all_order_title: '全部',
        going_order_title: '进行中',
        finish_order_title: '已完成',
        order_num: '订单号',
        order_status: '待付款',
        total_price: '合计',
        house_total_price: '房源费用合计',
        pick_up_drop_off_total_price: '接送机费用合计',
        order_reserve_date: '日期',
        people_num_date: '房客',
        deposit_num: '押金',
        deposit_will_return_back: '(押金将在退房成功后自动退还)',
        room_price: '房费',
        look_price_detail: '查看明细',
        price_around: '平均每晚价格四舍五入计算',
        clean_price: '清洁费',
        airbnb_fee_price: 'Airbnb服务费',
        one_clean_price: '房东收取的一次性清洁费，用于清洁房源',
        order_info: '订单信息',
        order_number: '订单号',
        order_time: '下单时间',
        pay_time: '付款时间',
        pay_type: '付款方式',
        pay: '付款',
        cancel_order_refund: '协商退款',
        cancel_order_refund_model_title: '请先与房东协商退款事项,你确定取消该订单进行退款吗？',
        cancel_order_refund_success: '已经申请，请和房东再次确认退款',
        cancel_order: '取消订单',
        cancel_order_model_title: '请先与房东协商退款事项,你确定取消该订单进行退款吗？',
        cancel_order_success: '订单取消成功',
        pay_money: '支付',
        people_num: '房客数',
        order_state_0: '待确认',
        order_state_1: '待支付',
        order_state_2: '待入住',
        order_state_3: '已超时',
        order_state_4: '已完成',
        order_state_5: '已取消',
        order_state_6: '房东拒绝',
        order_state_7: '支付失败',
        order_state_8: '退款申请中',
        order_state_9: '退款完成',
        order_state_10: '退款拒绝',
        nav_to_order_detail_page: '订单创建成功，正在跳转到订单详情',
        submit_confirm: '确认提交',
    },
    house: {
        house_max_people: '当前房源最大入住人数是',
        select_people_title: '选择人数',
        collect_success: '收藏成功',
        cancel_collect: '已经取消收藏',
        house_room: '室',
        house_bath_num: '卫',
        house_bed_num: '床',
        house_people_num: '最多入住%s人',
        house_check_in_check_out: '入住时间/退房时间',
        house_min_days: '最短入住时间',
        days: '天',
        cancel_policy: '退订政策',
        calendar_btn: '日历',
        ok_btn: '好的',
        cancel_policy_tips: '1、预订时间距離入住時間還有14天，可在预订后48小時內免費取消。',
        cancel_policy_tips2: '2、此後，距離入住時間還有15天退50%房費，15天之內不退還。',
        compare_price_other: '比价',
        house_chat_info: '聊天',
        copy_chat_title: '复制联系方式',
        copy_chat_tips: '直接点击对应的联系方式进行复制',
        copy_tips: '复制',
        copy_line_tips: '复制LINE',
        copy_wechat_tips: '复制Wechat',
        copy_chat_wechat: 'Wechat复制成功，已经复制到剪切板，快去微信里添加好友吧',
        copy_chat_line: 'LINE复制成功，已经复制到剪切板，快去添加好友吧',
        copy_chat_close_btn: '关闭',
        compare_price_airbnb: '比Airbnb房源',
        compare_price_agoda: '比Agoda房源',
        compare_price_booking: '比Booking房源',
        compare_price_percent: '优惠5% - 30%',
        add_lodger_tips: '您所填信息将不会被泄漏。仅因相关部门规定，入住中国民宿必须提供以下信息，您的信息将由我们储存并处理，完成本次预订，您同意我们可以向中国政府披露您的信息而不向您提供进一步通知。',
        add_new_lodger: '新增房客信息',
        plase_select_num_tips: '请选择和房客人数相同的旅客信息',
    },
    booking: {
        look_pay_method: '查看支付方式',
        submit_info_to_order: '提交订单',
        date: '日期',
        night: '晚',
        date_placeholder: '请选择日期',
        travellers_num: '房客(人数)',
        travellers_num_placeholder: '请选择入住的房客人数',
        num_per: '位',
        email: '邮箱',
        email_placeholder: '将向邮箱发送预订信息',
        email_placeholder_tips: '请填写邮箱接受预订信息',
        price_title: '费用摘要',
        room_price_total: '房费总额',
        room_fee: '房费',
        price_round: '平均每晚价格四舍五入计算',
        extra_people_fee: '额外人员费用',
        airbnb_fee_price: 'Airbnb服务费',
        clean_price: '清洁费',
        clean_price_tips: '房东收取的一次性清洁费，用于清洁房源',
        deposit: '入住押金',
        deposit_not: '无押金',
        deposit_tips: '退房后无物品丢失损坏，24小时内全额返回押金',
        total_price: '合计',
        house_total_price: '房源合计',
        pick_up_total_price: '接机合计',
        drop_off_total_price: '送机合计',
        people: '人',
        extra_people_max_num: '超过%s人收取',
        traveller_num_placeholder: '请选择入住人信息',
        traveller_people_num: '请选择%s个入住人信息',
        traveller_info_title: '入住人信息',
        traveller_info_title_placeholder: '请填写入住人信息',
        booking_house_tips_email: '填写的邮箱将接受预订信息，请再次确认是否准确。',
        'seven_cards': '七座车',
        'ten_cards': '十座车',
        'pick_up_name': ' 的接机',
        'drop_off_name': '的送机',
        'pick_up_at': '预订时间',
        'fight_no': '航班',
        'pick_up_people_num': '乘客人数',
    },
    home: {
        date_num_price: '日期/人数/价格',
        house_title: '民宿',
        people_num_format: '%s人',
    },
    calendar: {
        date_not_rent: '当前日期不可租',
        not_rent: '不可租',
        check_in: '入住',
        check_out: '退房',
        can_check_out: '可退房',
        check_in_check_out_not_one_day: '退房日期不能跟入住日期在同一天',
        check_in_less_than_check_out: '退房日期不能小于入住日期',
        check_in_on_not_rent_tips: '选择的日期包含不可租日期,请重新选择',
        placeholder_check_out_time: '请选择结束时间',
        check_date_min_days_tips: '选择入住的天数不能少于%s天',
        mon: '一',
        tue: '二',
        wed: '三',
        thur: '四',
        fri: '五',
        sat: '六',
        sun: '日',
        clear: '清除',
        confirm: '确认',
    },
    error: {
        error_no_page: '暂无此页面',
        nav_to_home_page: '前往首页',
    },
    ad: {
        page_title: '接送机服务',
        page_title_pick_up: '出发地：请输入地址',
        page_title_spot: '目的地：请选择机场',
        page_title_pick_up2: '出发地：请选择机场',
        page_title_spot2: '目的地：请输入地址',
        page_title_date: '请选择日期',
        page_title_time: '请选择时间',
        page_title_time_title: '时间选择',
        page_title_people_num: '乘客人数',
        page_title_search_btn: '搜索',
        page_title_safety_seat_num : '婴儿座椅（适用0-6岁）',
        page_title_fight_no_placeholder: '请输入您的航班',
        page_booking_detail_placeholder: '请填写航班号，方便司机进行接送',
        page_input_airport_placeholder: '请选择机场',
        page_input_spot_placeholder: '请输入地址',
        page_input_num_placeholder: '请选择乘客人数',
    }
};


export default zh_CN;
