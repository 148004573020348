/**
 * Created by author
 */
import * as user from './action-type'

let defaultState = {
    searchParams: {
        begin: '',
        end: '',
        priceMin: '',
        priceMax: '',
        num: '',
        roomNum:'',
        keyword: '',
        latitude: '',
        longitude: '',
        distance: '',
        areaId: '',
        categoryId: '',
        orderby: 'sortNum',
        order: 'desc',
    }, // 搜索参数
    categoryObj: null, // 搜索选择的分类对象
    prefix: '', // 登录注册时候选择的手机区号
    travellerPrefix: '', // 填写旅客信息时候选择的手机区号
    country: '', // 填写旅客可信息时候选择的国家、地区码
    countryName: '', // 填写旅客信息时候选择的国家、地区名字
    travellersInfo: {
        name: '',
        sexy: 1,
        identityCardType: 1,
        identityCardNo: '',
        phone: '',
        countryName: '中国',
        country: 'CN',
        prefix: '+86',
    }, // 正在填写的旅客信息，暂时保存
    selectedTravellersArr: [], // 已经选择的旅客信息
    travellersNum: '', // 需要选择的旅客人数
    bookingEmail: '', // 预订详情页的邮箱
    startAndEndDateAndNum: { // 选择的入住日期和结束日期
        startDate: '',
        endDate: '',
        num: '',
        peopleNum: 0
    },
    areaId: '', // 选择的国家省市区的id
    areaName: [], // 选择的国家省市区的名字数组
}

// 搜索条件
export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case user.SAVE_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: action.searchParams
            }
        case user.SAVE_SEARCH_CATEGORY_PARAMS:
            return {
                ...state,
                categoryObj: action.categoryObj
            }
        case user.PREFIX:
            return {
                ...state,
                prefix: action.datavalue
            }
        case user.TRAVELLER_PREFIX:
            return {
                ...state,
                travellerPrefix: action.datavalue
            }
        case user.COUNTRY:
            return {
                ...state,
                country: action.datavalue
            }
        case user.COUNTRY_NAME:
            return {
                ...state,
                countryName: action.datavalue
            }
        case user.TRAVELLERS_INFO:
            return {
                ...state,
                travellersInfo: action.datavalue
            }
        case user.SELECTED_TRAVELLERS_INFO:
            return {
                ...state,
                selectedTravellersArr: action.datavalue
            }
        case user.SAVE_TRAVELLERS_NUM:
            return {
                ...state,
                travellersNum: action.datavalue
            }
        case user.SAVE_BOOKING_EMAIL:
            return {
                ...state,
                bookingEmail: action.datavalue
            }
        case user.SAVE_START_END_DATE:
            return {
                ...state,
                startAndEndDateAndNum: action.datavalue
            }
        case user.SAVE_AREA_ID:
            return {
                ...state,
                areaId: action.datavalue
            }
        case user.SAVE_AREA_NAME:
            return {
                ...state,
                areaName: action.datavalue
            }
        default:
            return state
    }
}