/**
 * Created by author
 */
import React, {Component} from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom' // 路由的实现
import asyncComponent from '../utils/asyncComponent' // 实现异步加载模块的方法
// import storage from '../utils/storageUtils';
import cookie from 'react-cookies'
import { HOME_TOKEN_KEY_DEFAULT } from '../config/constants';

const Home = asyncComponent(() => import("../pages/home/home.js"))
const Search = asyncComponent(() => import("../pages/search/search.js"))
const Me = asyncComponent(() => import("../pages/me/me.js"))
const UserInfo = asyncComponent(() => import("../pages/me/userInfo.js"))
const Setting = asyncComponent(() => import("../pages/setting/setting.js"))
const SettingLanguage = asyncComponent(() => import("../pages/setting/settingLanguage.js"))
const SettingCurrency = asyncComponent(() => import("../pages/setting/settingCurrency.js"))
const Category = asyncComponent(() => import("../pages/category/category.js"))
const OrderList = asyncComponent(() => import("../pages/order/orderList.js"))
const OrderDetail = asyncComponent(() => import("../pages/order/orderDetail.js"))
const OrderBasic = asyncComponent(() => import("../pages/order/orderBasic.js"))
const LodgerInfo = asyncComponent(() => import("../pages/order/lodgerInfo.js"))
const AddEvaluation = asyncComponent(() => import("../pages/order/addEvaluation.js"))
const RefundRequest = asyncComponent(() => import("../pages/order/refundRequest.js"))
const Collect = asyncComponent(() => import("../pages/collect/collect.js"))
const Travellers = asyncComponent(() => import("../pages/traveller/travellers.js"))
const TravellersDetail = asyncComponent(() => import("../pages/traveller/travellersDetail.js"))
const Landlord = asyncComponent(() => import("../pages/landlord/landlord.js"))
const HouseDetail = asyncComponent(() => import("../pages/house/houseDetail.js"))
const HouseEvaluate = asyncComponent(() => import("../pages/house/houseEvaluate.js"))
const RefundPolicy = asyncComponent(() => import("../pages/house/refundPolicy.js"))
const HouseCalendar = asyncComponent(() => import("../pages/house/houseCalendar.js"))
const BookingDetail = asyncComponent(() => import("../pages/house/bookingDetail.js"))
const SelectLodger = asyncComponent(() => import("../pages/house/selectLodger.js"))
const AddLodger = asyncComponent(() => import("../pages/house/addLodger.js"))
const Pay = asyncComponent(() => import("../pages/pay/pay.js"))
const PayImg = asyncComponent(() => import("../pages/pay/payImg.js"))
const PayResult = asyncComponent(() => import("../pages/pay/payResult.js"))
const PayResultFailed = asyncComponent(() => import("../pages/pay/payResultFailed.js"))
const PayResultSuccess = asyncComponent(() => import("../pages/pay/payResultSuccess.js"))
const Login = asyncComponent(() => import("../pages/auth/login.js"))
const EmailLogin = asyncComponent(() => import("../pages/auth/emailLogin.js"))
const Register = asyncComponent(() => import("../pages/auth/register.js"))
const EmailRegister = asyncComponent(() => import("../pages/auth/emailRegister.js"))
const EmailVerify = asyncComponent(() => import("../pages/auth/emailVerify.js"))
const forgetPassword = asyncComponent(() => import("../pages/auth/forgetPassword.js"))
const ResetPassword = asyncComponent(() => import("../pages/setting/resetPassword.js"))
const SelectCode = asyncComponent(() => import("../pages/common/selectCode.js"))
const SelectAreaCode = asyncComponent(() => import("../pages/common/selectAreaCode.js"))
const Calendar = asyncComponent(() => import("../pages/common/calendar.js"))
const TermsOfService = asyncComponent(() => import("../pages/auth/termsOfService.js"))
const DetailMaps = asyncComponent(() => import("../pages/common/detailMaps.js"))

// 提示页面 房源已经下线
const OfflineHouse = asyncComponent(() => import("../pages/tips/offlineHouse.js"))
const ErrorPage = asyncComponent(() => import("../pages/tips/404.js"))
const ErrorServerPage = asyncComponent(() => import("../pages/tips/500.js"))
const NoStoreTips = asyncComponent(() => import("../pages/tips/noStoreTips.js"))
const BaseInfo = asyncComponent(() => import("../pages/tips/baseInfo.js"))

// 接送机
const AirportSearch = asyncComponent(() => import("../pages/airport/searchAirport.js"))
const AirportDetail = asyncComponent(() => import("../pages/airport/airportDetail.js"))
const AirportBookingDetail = asyncComponent(() => import("../pages/airport/airBookingDetail.js"))
/**
 * @param  {Protected:登陆拦截（函数组建）}
 * @param  {...[type]}
 * @return {还是一个Route组建，这个Route组建使用的是Route三大渲染方式（component、render、children）的render方式}。注意当component和render两种方式共存时，优先使用component方式渲染
 */

const Protected =  ({ component: Component, ...rest }) => {
    // let token = storage.getItem(HOME_TOKEN_KEY_DEFAULT) || ''
    let token = cookie.load(HOME_TOKEN_KEY_DEFAULT) || ''
    console.log(token)
    return (
        <Route
            {...rest}
            render={
                props => {
                    let redirectUrl = '/email/login?redirectUrl=' + props.location.pathname
                    if (token) {
                        return <Component {...props} />
                    } else {
                        return <Redirect to={redirectUrl}/>
                    }
                }
            }
        />
    )
}

export default class RouteConfig extends Component {
    render () {
        return (
            <Router>
                <Switch>
                    <Route path="/home" exact component= {Home}/>
                    <Route path="/search" exact component= {Search}/>
                    <Route path="/me" component= {Me}/>
                    <Route path="/category" component= {Category}/>
                    <Protected path="/order/list" exact component= {OrderList}/>
                    <Protected path="/order/detail/:orderNo" component= {OrderDetail}/>
                    <Route path="/order/basic/:orderNo" component= {OrderBasic}/>
                    <Protected path="/order/lodger/info/:orderNo" component= {LodgerInfo}/>
                    <Protected path="/evaluation/add/:orderNo" component= {AddEvaluation}/>
                    <Protected path="/refund/request/:orderNo" component= {RefundRequest}/>
                    <Protected path="/collect" component= {Collect}/>
                    <Protected path="/traveller/detail/:travellerId?" component={TravellersDetail}/>
                    <Protected path="/travellers/list" component={Travellers}/>
                    <Protected path="/landlord" component= {Landlord}/>
                    <Protected path="/user/info" component= {UserInfo}/>
                    <Route path="/setting/language" component= {SettingLanguage}/>
                    <Route path="/setting/currency" component= {SettingCurrency}/>
                    <Route path="/setting" component= {Setting}/>
                    <Route path="/house/detail/:houseId" component= {HouseDetail}/>
                    <Route path="/house/maps/detail" component= {DetailMaps}/>
                    <Route path="/house/evaluate/list/:houseId" component= {HouseEvaluate}/>
                    <Route path="/house/policy/:id" component= {RefundPolicy}/>
                    <Route path="/house/calendar/:houseId?" component= {HouseCalendar}/>
                    <Route path="/booking/detail/:houseId" component= {BookingDetail}/>
                    <Route path="/lodger/select/:houseId" component= {SelectLodger}/>
                    <Route path="/add/lodger" component= {AddLodger}/>
                    <Route path="/pay/img" component= {PayImg}/>
                    <Route path="/pay/list/:orderNo?" component= {Pay}/>
                    <Protected path="/pay/result/failed" component= {PayResultFailed}/>
                    <Protected path="/pay/result/success" component= {PayResultSuccess}/>
                    <Protected path="/pay/result/:orderNo?" component= {PayResult}/>
                    <Protected path="/reset/password" component= {ResetPassword}/>
                    <Route path="/email/login" component= {EmailLogin}/>
                    <Route path="/login" component= {Login}/>
                    <Route path="/email/register" component= {EmailRegister}/>
                    <Route path="/email/verify" component= {EmailVerify}/>
                    <Route path="/forget/password" component= {forgetPassword}/>
                    <Route path="/register" component= {Register}/>
                    <Route path="/select/code" component= {SelectCode}/>
                    <Route path="/select/area" component= {SelectAreaCode}/>
                    <Route path="/calendar" component= {Calendar}/>
                    <Route path="/terms/service" component= {TermsOfService}/>
                    <Route path="/tips/offline" component= {OfflineHouse}/>
                    <Route path="/tips/errorPage" component= {ErrorPage}/>
                    <Route path="/tips/errorServerPage" component= {ErrorServerPage}/>
                    <Route path="/tips/noStorePage" component= {NoStoreTips}/>
                    <Route path="/tips/baseInfo" component= {BaseInfo}/>
                    <Route path="/airport/search" component= {AirportSearch}/>
                    <Route path="/airport/detail" component= {AirportDetail}/>
                    <Route path="/airport/booking/detail" component= {AirportBookingDetail}/>
                    <Redirect exact from='/' to='/home'/>
                </Switch>
            </Router>
        )
    }
}